<template>
    <div class="container">
            <div class="row p-3" style="background-color: #444444">
                <div class="col-6">
                    <h3 class="text-white mb-0">Products</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <b-button variant="primary" to="/clients" class="font-weight-bold" style="border-radius: 0px !important;">CLIENT MANAGEMENT</b-button>
                </div>
            </div>

            <div class="row p-2" style="background-color: #EBEBEB">

                <div class="col-3 col-md-3">
                    <div class="row" style="background-color: dodgerblue"><strong class="p-2">PRODUCT OPTIONS</strong></div>
                    <div class="row" style="background-color: #ffffff;">
                        <div class="col-12 mt-2">
                            <p v-b-toggle.adret class="mb-1">Ad Verifier</p>
                            <b-collapse id="adret" visible accordion="my-accordion" role="tabpanel">
                                <ul style="list-style: none">
                                    <li @click="dataUsage = true">Data Usage</li>
                                    <li @click="clickUsage = true">Click Usage</li>
                                    <li>Add Ons</li>
                                </ul>
                            </b-collapse>
                        </div>
                        <div class="col-12 mt-2">
                            <p v-b-toggle.ev class="mb-1">Email Verifier</p>
                            <b-collapse id="ev" visible accordion="my-accordion" role="tabpanel">
                                <ul>
                                    <li>No Plan Type Yet</li>
                                </ul>
                            </b-collapse>
                        </div>
                        <div class="col-12 mt-2">
                            <p v-b-toggle.cc class="mb-1">Content Catcher</p>
                            <b-collapse id="cc" visible accordion="my-accordion" role="tabpanel">
                                <ul>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                </ul>
                            </b-collapse>
                        </div>

                        <div class="col-12 mt-2 mb-1">
                            <hr class="mt-5">
                            <p v-b-toggle.mp class="mb-1">My Products</p>
                            <b-collapse id="mp" visible accordion="my-accordion" role="tabpanel">
                                <ul>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                    <li>Data</li>
                                </ul>
                            </b-collapse>
                        </div>
                    </div>
                </div>
                <div class="col-8 col-md-8 pl-5">
                    <div class="row border-bottom"><strong class="p-2">CAMPAIGN SELECTION - </strong></div>
                    <div class="row mt-2">

<!--                        <b-tabs pills>-->

<!--                        </b-tabs>-->

                        <div class="col-5 col-md-5" style="background-color: #ffffff; border-radius: 5px">
                            <div class="row">
                                <div class="col-4"><h1>AV</h1></div>
                                <div class="col-8">ResiCity</div>
                            </div>
                            <div class="row">
                                <div class="col-12"><small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 col-md-12">
                                    <b-form-group label="PLAN SIZE" v-slot="{ ariaDescribedby }">
                                        <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="A">500K</b-form-radio>
                                        <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="B">1M</b-form-radio>
                                        <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="B">2M</b-form-radio>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row p-3" style="background-color: #444444">
                <div class="col-6">
                    <b-button variant="dark" class="font-weight-bold" style="border-radius: 0px !important;">BACK</b-button>
                </div>

                <div class="col-6 d-flex justify-content-end">
                    <b-button variant="primary" class="font-weight-bold" style="border-radius: 0px !important;">CONFIGURE DOMAIN</b-button>
                </div>
            </div>
        </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Products",

    data() {
        return {
            plans: '',
            planDriven: '',
            planProxy: '',
            selectedPlans: [],
            selectedProxy: [],

            dataUsage: false,
            clickUsage: false,

        }
    },
    created() {
        this.loadPlans().finally(() => {
            this.$root.preloader = false;
        })

    },
    watch: {
        dataUsage(value) {
            if (value) {
                this.loadDataPlans();
            }
        },
        clickUsage(value) {
            if (value) {
                this.loadClickPlans();
            }
        },
    },
    methods: {
        loadPlans: function() {
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/products/plans`, {params:request}).then(function(resp){
                //Store the stats
                this.plans = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadClickPlans: function() {
            this.selectedPlans = []
            this.dataUsage = false;
            this.plans.filter( (plan) => {
                if (plan.Driven === 'click') {
                    this.planDriven = 'click'
                    this.selectedPlans.push(plan)
                }
            })
        },
        loadDataPlans: function() {
            this.selectedPlans = []
            this.clickUsage = false
            this.plans.filter( (plan) => {
                if (plan.Driven === 'data') {
                    this.planDriven = 'data'
                    this.selectedPlans.push(plan)
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
